<template>
  <section id="knowledge-base-search">
    <b-card no-body class="knowledge-base-bg text-center">
      <b-card-body class="card-body">
        <h2 class="text-primary">Welcome to Emoty admin panel</h2>
      </b-card-body>
    </b-card>
  </section>
</template>
<script>
import { BCard, BCardBody } from "bootstrap-vue";

export default {
  name: "Home",
  components: {
    BCard,
    BCardBody,
  },
};
</script>

<style lang="scss">
#knowledge-base-search h2 {
  height: 30vh;
  line-height: 30vh;
}
</style>
